import { SubscriberTier } from 'domains/Subscription/Subscription.types';
import { CurrencyType } from '../Common.types';
import { MessengerPartner } from './Messenger.types';

export interface AbstractMessage {
    id: number;
    createdAt: string;
    sender: MessengerPartner;
    isDeleted?: boolean;
    isSeen?: boolean | null;
    isLoading?: boolean;
    isFailed?: boolean;
    isDeletedByMember?: boolean;
}

export interface TextMessage extends AbstractMessage {
    type: MessageTypesEnum.Text;
    body: string;
    isTitle?: boolean;
}

export interface AudioMessage extends AbstractMessage {
    type: MessageTypesEnum.Audio;
    versions: AudioMessageVersion[];
    status: MediaStatusEnum;
    file?: File;
}

export interface ImageMessage extends AbstractMessage, PricedMediaAbstract {
    type: MessageTypesEnum.Image;
    thumbnails: Image[];
    versions: ImageMessageVersion;
    status: MediaStatusEnum;
    isPurchased: boolean;
    attachmentId: number | null;
    file?: File;
    title?: string;
    titleMessage?: TextMessage;
}

export interface VideoMessage extends AbstractMessage, PricedMediaAbstract {
    type: MessageTypesEnum.Video;
    thumbnails: Image[];
    versions: VideoMessageVersion;
    status: MediaStatusEnum;
    isPurchased: boolean;
    attachmentId: number;
    file?: File;
    title?: string;
    titleMessage?: TextMessage;
}

export interface CallInfoMessage extends AbstractMessage {
    type: MessageTypesEnum.CallInfo;
    event: CallInfoEvent;
    body: string;
}

export interface OfflineSurpriseMessage extends AbstractMessage, PricedMediaAbstract {
    type: MessageTypesEnum.OfflineSurprise;
    body: string;
    surpriseName: string;
    customText: string | null;
    assets: {
        preview: SurpriseAsset[];
        animations: SurpriseAsset[];
    };
    surpriseVariant: OfflineSurpriseVariant;
}

export type OfflineSurpriseVariant = `action` | `emotion` | `surprise` | `tip` | `toy`;

export interface StoryReplyMessage extends AbstractMessage {
    type: MessageTypesEnum.StoryReply;
    thumbnails: Image[];
    storyItemId: number;
    status: StoryStatusEnum | null;
    body: string;
}

export interface GiftMessage extends AbstractMessage {
    type: MessageTypesEnum.Gift;
    body: string;
    thumbnailUrl: string;
    giftId: string;
    giftName: string;
}

export interface GoodieBasketMessage extends AbstractMessage {
    type: MessageTypesEnum.GiftBasket;
    amount: number;
}

export interface PricedMediaAbstract {
    price: number;
    creditType: CurrencyType;
}

export interface AudioMessageVersion {
    uri: string;
    duration: number;
}

export interface ImageMessageVersion {
    content: Image;
    mobile: Image;
    fullHD: Image;
}

export interface VideoMessageVersion {
    content: Video;
    mobile: Video;
    fullHD: Video;
}

export interface Image {
    width: number;
    height: number;
    uri: string;
}

export interface Video {
    width: number;
    height: number;
    uri: string;
    duration: number;
}

export interface SurpriseAsset {
    fileType: string;
    url: string;
}

export interface MessengerMemberLastTextMessage extends LastMessageAbstract {
    type: MessageTypesEnum.Text;
    body: string;
}

export interface MessengerMemberLastAudioMessage extends LastMessageAbstract {
    type: MessageTypesEnum.Audio;
}

export interface MessengerMemberLastImageMessage extends LastMessageAbstract {
    type: MessageTypesEnum.Image;
}

export interface MessengerMemberLastVideoMessage extends LastMessageAbstract {
    type: MessageTypesEnum.Video;
}

export interface MessengerMemberLastOfflineSurpriseMessage extends LastMessageAbstract {
    type: MessageTypesEnum.OfflineSurprise;
    price: number;
    creditType: CurrencyType;
    variant: OfflineSurpriseVariant;
}

export interface MessengerMemberLastCallInfoMessage extends LastMessageAbstract {
    type: MessageTypesEnum.CallInfo;
    event: CallInfoEvent;
    senderName: string;
}

export interface MessengerMemberLastGiftMessage extends LastMessageAbstract {
    type: MessageTypesEnum.Gift;
    giftName: string;
}

export interface MessengerMemberLastGoodieBasket extends LastMessageAbstract {
    type: MessageTypesEnum.GiftBasket;
    amount: number;
}

export interface MessengerMemberLastMemberSpentInteraction extends LastMessageAbstract {
    type: MemberInteractionEnum.MemberSpent;
}

export interface MessengerMemberLastFavouritedInteraction extends LastMessageAbstract {
    type: MemberInteractionEnum.AddedToFavorites;
}

export interface MessengerMemberLastContentLikedInteraction extends LastMessageAbstract {
    type: MemberInteractionEnum.ContentLiked;
    mediaType: InteractionMediaType;
}

export interface MessengerMemberLastContentCommentedInteraction extends LastMessageAbstract {
    type: MemberInteractionEnum.ContentCommented;
    mediaType: InteractionMediaType;
}

export interface MessengerMemberLastFreePeekInteraction extends LastMessageAbstract {
    type: MemberInteractionEnum.FreePeek;
}

export type MessengerMemberLastMessage =
    | MessengerMemberLastTextMessage
    | MessengerMemberLastAudioMessage
    | MessengerMemberLastImageMessage
    | MessengerMemberLastVideoMessage
    | MessengerMemberLastOfflineSurpriseMessage
    | MessengerMemberLastCallInfoMessage
    | MessengerMemberLastGiftMessage
    | MessengerMemberLastMemberSpentInteraction
    | MessengerMemberLastFavouritedInteraction
    | MessengerMemberLastContentLikedInteraction
    | MessengerMemberLastContentCommentedInteraction
    | MessengerMemberLastGoodieBasket
    | MessengerMemberLastFreePeekInteraction;

export type SendableMessageTypes =
    | MessageTypesEnum.Audio
    | MessageTypesEnum.Image
    | MessageTypesEnum.Text
    | MessageTypesEnum.Video;

export interface MessengerMember {
    id: number;
    type: MessengerMemberType;
    partner: MessengerMemberPartner;
    isDeleted?: boolean;
    isBlocked: boolean;
    lastMessageTimeStamp: string;
    lastMessage: MessengerMemberLastMessage;
    hasNewInteraction?: boolean;
    unreadMessageCount?: number;
    chatThreadId?: number;
    isPinned?: boolean;
    isRestored?: boolean;
    streak?: {
        length: number;
        isExpiring: boolean;
    };
}

export interface MessengerMemberPartner {
    id: number;
    name: string;
    nameInitialLetter: string;
    loyalty: {
        level: LoyaltyLevelEnum | null;
        isVisible: boolean | undefined;
    };
    fanclubPackageType?: SubscriberTier | null;
    hasProMembership?: boolean;
}

export interface ThreadDetails {
    id: number;
    partner: ThreadDetailsPartner;
    isDeleted?: boolean;
    isBlocked?: boolean;
    streak?: {
        length: number;
        isExpiring: boolean;
    };
}

interface ThreadDetailsPartner {
    id: number;
    name: string;
    nameInitialLetter: string;
    loyalty: {
        level: LoyaltyLevelEnum | null;
        isVisible: boolean | undefined;
    };
    fanclubPackageType?: SubscriberTier | null;
    hasProMembership?: boolean;
}

interface MemberInteraction {
    type: MemberInteractionEnum.AddedToFavorites;
    createdAt: string;
}

interface MemberSpentInteraction {
    type: MemberInteractionEnum.MemberSpent;
    createdAt: string;
    creditsSpent: number;
    pretenceName: SpentTypeEnum;
}

interface ContentInteraction {
    type: MemberInteractionEnum.ContentLiked | MemberInteractionEnum.ContentCommented;
    createdAt: string;
    contentId: string;
    contentType: ContentInteractionTypeEnum;
    privacy: ContentInteractionPrivacyEnum;
}

export interface LastMessageAbstract {
    direction?: MessageDirection;
}

export enum MessengerMemberType {
    Recommended = 'recommended',
    Thread = 'thread',
}

export enum CallInfoEvent {
    CallMissed = 'call.missed',
    CallStarted = 'call.started',
    CallFinished = 'call.finished',
}

export enum MediaStatusEnum {
    MediaAvailable = 'media-available',
    ValidationFailed = 'validation-failed',
}

export enum StoryStatusEnum {
    Created = 'created',
    Rejected = 'rejected',
    Activated = 'activated',
    Deleted = 'deleted',
    Expired = 'expired',
}

export enum PartnerTypeEnum {
    Member = 'member',
    Performer = 'performer',
}

export enum LoyaltyLevelEnum {
    NotInLoyalty = 'Not in loyalty',
    Crush = 'Crush',
    Lover = 'Lover',
    Sweetheart = 'Sweetheart',
    Lancelot = 'Lancelot',
    Romeo = 'Romeo',
    Casanova = 'Casanova',
    Signature = 'Signature',
    Bronze = 'Bronze',
    Silver = 'Silver',
    Gold = 'Gold',
    Platinum = 'Platinum',
    Diamond = 'Diamond',
    Eros = 'Eros',
}

export enum MessageTypesEnum {
    Text = 'text',
    Audio = 'audio',
    Image = 'image',
    Video = 'video',
    CallInfo = 'call-info',
    StoryReply = 'story-reply',
    OfflineSurprise = 'offline-surprise',
    Gift = 'gift',
    GiftBasket = 'gift-basket',
}

export enum MemberInteractionEnum {
    MemberSpent = 'memberSpent',
    AddedToFavorites = 'addedToFavorites',
    ContentLiked = 'contentLiked',
    ContentCommented = 'contentCommented',
    MessageReceived = 'messageReceived',
    FreePeek = 'freePeek',
}

export enum ContentInteractionTypeEnum {
    Video = 'video',
    Image = 'image',
    Note = 'note',
}

export enum ContentInteractionPrivacyEnum {
    Free = 'free',
    Exclusive = 'exclusive',
}

export enum SpentTypeEnum {
    PrivateShow = 'private show',
    Surprise = 'surprise',
    Snapshot = 'snapshot',
    MyContentVideo = 'MyContent video purchase',
    MyContentImageFolder = 'MyContent photo album purchase',
    MyStory = 'my story',
    StoryHighlight = 'story highlight',
    HotShow = 'hot show tip',
    Battle = 'battle tip',
    ActionRequests = 'action request',
    ToyInteractionPrivateLiveShow = 'Interactive Toy - Private',
    ToyInteractionHotShow = 'Interactive Toy - Hot Show',
    ToyInteractionVipShow = 'Interactive Toy - VIP Show',
    ToyInteractionFreeLiveShow = 'Interactive Toy - Free Live',
    ToyInteractionVideoCall = 'Interactive Toy - Video Call',
    FreemiumTipLive = 'Freemium Tip - Live',
    FreemiumTipVipShow = 'Freemium Tip - VIP Show',
    FreemiumTipVideoCall = 'Freemium Tip - Video Call',
}

export type InteractionMediaType = 'video' | 'image' | 'note' | undefined;

export type MemberInteractionData = MemberInteraction | MemberSpentInteraction | ContentInteraction;

export type PurchasableMessage = ImageMessage | VideoMessage;

export type MessengerFeatures = 'gifts' | 'show-deleted' | 'show-seen-status';

export type MessageDirection = 'sent' | 'received';
