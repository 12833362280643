import { CommonMessage } from 'domains/Messenger/Messenger.types';
import { TranslatableMessage, UICommonMessage } from './Messages.types';

export const createLoadingMessage = (message: CommonMessage) => ({
    ...message,
    isLoading: true,
    isFailed: false,
});

export const createFailedMessage = (message: CommonMessage) => ({
    ...message,
    isLoading: false,
    isFailed: true,
});

export const createSucceededMessage = (message: CommonMessage) => ({
    ...message,
    isLoading: false,
    isFailed: false,
});

export const resetMessage = (message: CommonMessage): CommonMessage => ({
    ...message,
    isLoading: false,
    isFailed: false,
});

export const findMessageIndex = (messages: CommonMessage[], id: number) =>
    messages.findIndex((message) => message.id === id);

export const isTranslatableMessage = (message: UICommonMessage): message is TranslatableMessage => {
    return 'translatedText' in message || 'translationShown' in message || 'translationRequestStatus' in message;
};
