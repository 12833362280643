import _ from '@dh-fe/translate';
// eslint-disable-next-line no-restricted-imports
import dayjs from 'global/dayjsExtended';
import { CurrencyType } from 'domains/Common.types';
import { differenceInYears, differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import { FanClubSubscriptionPackage } from 'apiClients/msc-api-gateway/proxy/bffweb/messenger/models';
import { SubscriberTier } from 'domains/Subscription/Subscription.types';
import {
    MessengerMember,
    TextMessage,
    MessageTypesEnum,
    ThreadDetails,
    CallInfoMessage,
    GiftMessage,
    MessengerMemberLastMessage,
    StoryReplyMessage,
    PartnerTypeEnum,
    OfflineSurpriseVariant,
    OfflineSurpriseMessage,
} from '../Messages.types';
import { CommonMessage } from '../Messenger.types';

export const mapMessengerMemberToThreadDetails = (messengerMember: MessengerMember): ThreadDetails => {
    const { id, partner, isDeleted, isBlocked, streak } = messengerMember;
    const { name, nameInitialLetter, loyalty, id: partnerId, fanclubPackageType, hasProMembership } = partner;
    const mappedObject = {
        id,
        partner: {
            id: partnerId,
            name,
            nameInitialLetter,
            loyalty,
            fanclubPackageType,
            hasProMembership,
        },
        isDeleted,
        isBlocked,
    };

    if (streak) {
        return {
            ...mappedObject,
            streak,
        };
    }

    return mappedObject;
};

export const getInitialLetter = (name: string) => {
    return name.substring(0, 1).toUpperCase();
};

export const getLastMessage = (lastMessage: CommonMessage): MessengerMemberLastMessage => {
    const { type } = lastMessage;
    const { Text, Audio, Image, Video, OfflineSurprise, CallInfo, Gift, StoryReply, GiftBasket } = MessageTypesEnum;

    const direction = lastMessage.sender.type === PartnerTypeEnum.Performer ? 'sent' : 'received';

    switch (type) {
        case Text: {
            return {
                type: Text,
                body: (lastMessage as TextMessage).body,
                direction,
            };
        }

        case Audio: {
            return { type: Audio, direction };
        }

        case Image: {
            return { type: Image, direction };
        }

        case Video: {
            return { type: Video, direction };
        }

        case OfflineSurprise: {
            const { price, creditType, surpriseVariant } = lastMessage as OfflineSurpriseMessage;
            const direction = lastMessage.sender.type === PartnerTypeEnum.Performer ? 'sent' : 'received';
            return getOfflineSurpriseLastMessage(price, creditType, direction, surpriseVariant);
        }

        case CallInfo: {
            const callInfoMsg = lastMessage as CallInfoMessage;
            return { type: CallInfo, event: callInfoMsg.event, senderName: callInfoMsg.sender.name, direction };
        }

        case Gift: {
            return { type: Gift, giftName: (lastMessage as GiftMessage).giftName, direction };
        }

        case StoryReply: {
            // we don't have a last message for story replies
            return { type: Text, body: (lastMessage as StoryReplyMessage).body, direction };
        }

        case GiftBasket: {
            return { type: GiftBasket, amount: lastMessage.amount };
        }
    }
};

export const getOfflineSurpriseLastMessage = (
    price: number,
    creditType: CurrencyType,
    direction: 'sent' | 'received',
    variant: OfflineSurpriseVariant,
): MessengerMemberLastMessage => {
    const { OfflineSurprise } = MessageTypesEnum;
    const lastMessage: MessengerMemberLastMessage = {
        type: OfflineSurprise,
        price,
        creditType,
        direction,
        variant,
    };

    return lastMessage;
};

export const calculateLastMessageTime = (createdAt: string) => {
    const createdAtDate = new Date(createdAt);
    const currentDate = new Date();
    const years = Math.floor(differenceInYears(currentDate, createdAtDate));
    const months = Math.floor(dayjs(currentDate).diff(createdAtDate, 'months'));
    const days = Math.floor(differenceInDays(currentDate, createdAtDate));
    const hours = Math.floor(differenceInHours(currentDate, createdAtDate));
    const minutes = Math.floor(differenceInMinutes(currentDate, createdAtDate));

    if (years > 1) {
        return _`{years} years ago`.withData({ years });
    }
    if (years === 1) {
        return _`a year ago`;
    }
    if (months > 1) {
        return _`{months} months ago`.withData({ months });
    }
    if (months === 1) {
        return _`a month ago`;
    }
    if (days > 1) {
        return _`{days} days ago`.withData({ days });
    }
    if (days === 1) {
        return _`a day ago`;
    }
    if (hours > 1) {
        return _`{hours} hours ago`.withData({ hours });
    }
    if (hours === 1) {
        return _`an hour ago`;
    }
    if (minutes > 1) {
        return _`{minutes} minutes ago`.withData({ minutes });
    }
    if (minutes === 1) {
        return _`a minute ago`;
    }

    return _`a few seconds ago`;
};

export const mapFanclubPackageType = (
    fanClubSubscription?: FanClubSubscriptionPackage | null,
): SubscriberTier | null | undefined => {
    if (!fanClubSubscription) return undefined;

    return fanClubSubscription.type;
};
