import { Products } from 'configs/globalConfig';
import { GameTypeEnum } from 'domains/Awards/Awards.types';
import { cookieNameCreator } from 'utils/storage/cookies';

const {
    Newbies,
    OranumWeeklyVideoCallAwards,
    OranumWeeklyMessagesAwards,
    OranumWeeklyNewModelsAwards,
    OranumWeeklySurprisesAwards,
    VideoCallConversion,
    FreeChatConversion,
    HotDealConversion,
    VipShowConversion,
    TeaserConversion,
    RisingStar,
} = GameTypeEnum;

export const AWARDS_COMPETITORS_LIMIT = 100;
export const AWARDS_COMPETITORS_PREFETCH_COUNT = 5;
export const AWARDS_MAX_PRIZE_PER_CATEGORY_LIVEJASMIN = 300;
export const AWARDS_MAX_PRIZE_PER_CATEGORY_NON_LIVEJASMIN = 500;

const AWARDS_GAME_TYPE_ORDER_ORANUM = [
    OranumWeeklyNewModelsAwards,
    OranumWeeklyVideoCallAwards,
    OranumWeeklyMessagesAwards,
    OranumWeeklySurprisesAwards,
];

const AWARDS_GAME_TYPE_ORDER_LJ = [
    Newbies,
    RisingStar,
    VideoCallConversion,
    FreeChatConversion,
    HotDealConversion,
    VipShowConversion,
    TeaserConversion,
];

export const AWARDS_GAME_TYPE_ORDER: { [key in Products]: GameTypeEnum[] } = {
    livejasmin: AWARDS_GAME_TYPE_ORDER_LJ,
    oranum: AWARDS_GAME_TYPE_ORDER_ORANUM,
};

export const NEW_MODEL_GAMES = [GameTypeEnum.OranumWeeklyNewModelsAwards, GameTypeEnum.Newbies];

export const AWARDS_EMPTY_LIST_LENGTH = 5;
export const AWARDS_DAYS_LIMIT = 60;

// +1 week after the new awards game starts
export const ORANUM_AWARDS_HISTORY_START = '2021-05-31T10:00:00+02:00';
export const ORANUM_SURPRISES_HISTORY_START = '2021-06-21T10:00:00+02:00';
export const FREE_SHOW_AWARDS_HISTORY_START = '2021-11-22T12:00:00+01:00';
export const GOODIE_BASKET_AWARDS_HISTORY_START = '2022-03-14T12:00:00+01:00';
export const FAN_CLUB_AWARDS_HISTORY_START = '2022-03-14T12:00:00+01:00';
export const CATEGORY_BASED_AWARDS_HISTORY_START = '2022-10-24T12:00:00+02:00';
export const CONVERSION_BASED_AWARDS_HISTORY_START = '2023-08-14T12:00:00+02:00';
export const TEASER_CONVERSION_BASED_AWARDS_HISTORY_START = '2023-10-16T12:00:00+02:00';
export const SHORTS_AND_LOCATION_AWARDS_HISTORY_START = '2023-12-04T12:00:00+01:00';
export const RISING_STAR_AWARDS_HISTORY_START = '2024-08-12T12:00:00+02:00';

export const RISING_STAR_AWARDS_NEW_UNTIL = '2024-10-04T12:00:00+02:00';

export const COOKIE_AWARDS_PAGE_SHOWN_ID = cookieNameCreator('functional', 'awardsPageShown');
