import sendGaEvent from 'utils/ga';

const handleTrackEvent = (label: string) => {
    sendGaEvent({
        category: 'landing page',
        label,
        action: 'gdpr pop-up',
    });
};

const bannerAppeared = () => {
    handleTrackEvent('appeared');
};

const acceptCookies = () => {
    handleTrackEvent('accept cookies');
};

const rejectCookies = () => {
    handleTrackEvent('reject cookies');
};

const manageCookies = () => {
    handleTrackEvent('manage cookies');
};

const viewPolicy = () => {
    handleTrackEvent('cookie policy');
};

const confirmCookies = (isAnalyticalEnabled: boolean, isFunctionalityEnabled: boolean) => {
    const analyticalStatus = (isAnalyticalEnabled && 'enabled') || 'disabled';
    const functionalityStatus = (isFunctionalityEnabled && 'enabled') || 'disabled';
    handleTrackEvent(`confirm my choices: analytical ${analyticalStatus}, functionality ${functionalityStatus}`);
};

const tracking = {
    bannerAppeared,
    acceptCookies,
    rejectCookies,
    manageCookies,
    viewPolicy,
    confirmCookies,
};

export default tracking;
