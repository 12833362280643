import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PurchasableMessage } from 'domains/Messenger/Messages.types';
import { ParticipantType } from 'domains/Messenger/Messenger.types';
import { CachedTranslationMessage } from 'domains/Messenger/MessengerTranslation/MessengerTranslation.types';
// eslint-disable-next-line no-restricted-imports
import { MessageUpdateBody } from 'pages/Messenger/hooks/useMessengerJaws';
import { MessagesState, TranslatableMessage, UICommonMessage } from './Messages.types';
import {
    createFailedMessage,
    createLoadingMessage,
    findMessageIndex,
    resetMessage,
    createSucceededMessage,
} from './Messages.utils';

export const messagesInitialState: MessagesState = {};

const messagesSlice = createSlice({
    name: 'messenger',
    initialState: messagesInitialState,
    reducers: {
        fetchMessagesStarted: (state, action: PayloadAction<number>) => {
            state[action.payload] = {
                entries: [],
                isLoading: true,
                errorMessage: null,
                cursor: null,
            };
        },
        fetchMessagesSucceeded: (
            state,
            action: PayloadAction<{ threadId: number; messages: UICommonMessage[]; cursor: string }>,
        ) => {
            const { threadId, messages, cursor } = action.payload;
            state[threadId].entries = messages;
            state[threadId].isLoading = false;
            state[threadId].cursor = cursor;
        },
        fetchMessagesFailed: (state, action: PayloadAction<{ threadId: number; errorMessage: string }>) => {
            const { threadId, errorMessage } = action.payload;
            state[threadId].isLoading = false;
            state[threadId].errorMessage = errorMessage;
        },
        fetchPreviousMessagesStarted: (state, action: PayloadAction<number>) => {
            state[action.payload].isLoading = true;
            state[action.payload].errorMessage = null;
        },
        fetchPreviousMessagesSucceeded: (
            state,
            action: PayloadAction<{ threadId: number; messages: UICommonMessage[]; cursor: string }>,
        ) => {
            const { threadId, messages, cursor } = action.payload;
            state[threadId].isLoading = false;
            state[threadId].entries.unshift(...messages);
            state[threadId].cursor = cursor;
        },
        fetchPreviousMessagesFailed: (state, action: PayloadAction<{ threadId: number; errorMessage: string }>) => {
            const { threadId, errorMessage } = action.payload;
            state[threadId].isLoading = false;
            state[threadId].errorMessage = errorMessage;
        },
        sendMessageStarted: (state, action: PayloadAction<{ threadId: number; message: UICommonMessage }>) => {
            const { threadId, message } = action.payload;
            const loadingMessage = createLoadingMessage(message);

            if (!state[threadId]) {
                state[threadId] = {
                    entries: [],
                    isLoading: false,
                    errorMessage: null,
                    cursor: null,
                };
            }

            const retryMessage = state[threadId].entries.find((entry) => entry.id === loadingMessage.id);

            if (retryMessage) {
                const index = findMessageIndex(state[threadId].entries, loadingMessage.id);
                state[threadId].entries[index].isFailed = false;
                state[threadId].entries[index].isLoading = true;
            } else {
                state[threadId].entries.push(loadingMessage);
            }
        },
        sendMessageSucceeded: (
            state,
            action: PayloadAction<{ threadId: number; message: UICommonMessage; messageId: number }>,
        ) => {
            const { threadId, message, messageId } = action.payload;

            /**
             * You can send mass message to contacts which were not opened before thus we don't have it in the store yet,
             * but we are getting the jaws notifications of the successful delivery.
             * We need to return with non-valid entities since there is no message in to store yet to transform.
             * */
            if (!state[threadId]) return;

            const index = findMessageIndex(state[threadId].entries, messageId);
            const savedMessage = createSucceededMessage(message);
            const messages = state[threadId].entries;
            // Temporary fix until we don't get from the backend that we need to show or hide the read receipts after a new message sent
            if (messages.length > 1) {
                savedMessage.isSeen =
                    messages[0].isSeen === true || messages[0].isSeen === false ? false : messages[0].isSeen;
            }
            state[threadId].entries[index] = savedMessage;
        },
        sendMessageFailed: (
            state,
            action: PayloadAction<{ threadId: number; message: UICommonMessage; errorMessage: string }>,
        ) => {
            const { threadId, message, errorMessage } = action.payload;
            const index = findMessageIndex(state[threadId].entries, message.id);
            const failedMessage = createFailedMessage(message);

            state[threadId].entries[index] = failedMessage;
            state[threadId].errorMessage = errorMessage;
        },
        deleteMessageStarted: (state, action: PayloadAction<{ threadId: number; messageId: number }>) => {
            const { threadId, messageId } = action.payload;
            const index = findMessageIndex(state[threadId].entries, messageId);
            const loadingMessage = createLoadingMessage(state[threadId].entries[index]);

            state[threadId].entries[index] = loadingMessage;
        },
        deleteMessageSucceeded: (state, action: PayloadAction<{ threadId: number; messageId: number }>) => {
            const { threadId, messageId } = action.payload;
            const index = findMessageIndex(state[threadId].entries, messageId);

            if (index > -1) {
                state[threadId].entries[index].isDeleted = true;
            }
        },
        deleteMessageFailed: (state, action: PayloadAction<{ threadId: number; messageId: number }>) => {
            const { threadId, messageId } = action.payload;
            const index = findMessageIndex(state[threadId].entries, messageId);
            const resettedMessage = resetMessage(state[threadId].entries[index]);

            state[threadId].entries[index] = resettedMessage;
        },
        deleteMessageFromStore: (
            state,
            action: PayloadAction<{ threadId: number; messageId: number; initiator: ParticipantType }>,
        ) => {
            const { threadId, messageId, initiator } = action.payload;

            if (!state[threadId]) {
                console.warn(`There is no thread with id: '${threadId}'.'`);
                return;
            }

            const index = findMessageIndex(state[threadId].entries, messageId);

            if (index > -1) {
                const entry = state[threadId].entries[index];

                entry.isDeleted = true;
                entry.isDeletedByMember = initiator === 'member';
            }
        },
        deleteThreadMessages: (state, action: PayloadAction<number>) => {
            if (state[action.payload]) {
                delete state[action.payload];
            }
        },
        messageReceived: (state, action: PayloadAction<{ threadId: number; message: UICommonMessage }>) => {
            const { threadId, message } = action.payload;

            if (!state[threadId]) {
                state[threadId] = {
                    entries: [],
                    isLoading: false,
                    errorMessage: null,
                    cursor: null,
                };
            }

            state[threadId].entries.push(message);
        },
        updatePurchasableMessage: (state, action: PayloadAction<MessageUpdateBody>) => {
            const {
                body,
                body: { id, threadId },
            } = action.payload;
            const entry = state[threadId].entries.find((entry) => entry.id === id);

            (entry as PurchasableMessage).isPurchased = !!body.data.isPurchased;
        },
        updateDeletedMessage: (state, action: PayloadAction<{ threadId: number; messageId: number }>) => {
            const { threadId, messageId } = action.payload;
            const index = findMessageIndex(state[threadId].entries, messageId);

            if (index > -1) {
                state[threadId].entries[index].isDeleted = false;
                state[threadId].entries[index].isRestored = true;
                state[threadId].entries[index].isLoading = false;
            }
        },
        updateSeenMessages: (state, action: PayloadAction<{ threadId: number; lastMessageId: number }>) => {
            const { threadId, lastMessageId } = action.payload;

            state[threadId].entries
                .slice()
                .reverse()
                .some((message: UICommonMessage) => {
                    if (message.isSeen === false && message.id <= lastMessageId) {
                        message.isSeen = true;
                        return false;
                    }
                    return message.isSeen === true;
                });
        },
        restoreMessagesByThread: (state, action: PayloadAction<{ threadId: number }>) => {
            const { threadId } = action.payload;
            if (!state[threadId]) return;
            const mappedEntries = state[threadId].entries.map((entry) => ({
                ...entry,
                isDeleted: false,
                isRestored: false,
            }));

            state[threadId].entries = mappedEntries;
        },
        translateMessageStarted: (state, action: PayloadAction<{ threadId: number; messageId: number }>) => {
            const { threadId, messageId } = action.payload;
            const index = findMessageIndex(state[threadId].entries, messageId);

            (state[threadId].entries[index] as TranslatableMessage).translationRequestStatus = 'pending';
        },
        translateMessageSucceeded: (
            state,
            action: PayloadAction<{ threadId: number; messageId: number; translatedText: string }>,
        ) => {
            const { threadId, messageId, translatedText } = action.payload;
            const index = findMessageIndex(state[threadId].entries, messageId);

            if (index > -1) {
                (state[threadId].entries[index] as TranslatableMessage).translatedText = translatedText;
                (state[threadId].entries[index] as TranslatableMessage).translationShown = true;
                (state[threadId].entries[index] as TranslatableMessage).translationRequestStatus = 'resolved';
            }
        },
        translateMessageFailed: (state, action: PayloadAction<{ threadId: number; messageId: number }>) => {
            const { threadId, messageId } = action.payload;
            const index = findMessageIndex(state[threadId].entries, messageId);

            if (index > -1) {
                (state[threadId].entries[index] as TranslatableMessage).translationRequestStatus = 'rejected';
            }
        },
        setTranslatedMessagesInThread: (
            state,
            action: PayloadAction<{ threadId: number; translatedMessages: CachedTranslationMessage[] }>,
        ) => {
            const { threadId, translatedMessages } = action.payload;

            const translatedMessageIds = translatedMessages.map((message) => message.messageId);

            state[threadId].entries = state[threadId].entries.map((message) => {
                const cachedTranslationMessageIndex = translatedMessageIds.indexOf(message.id);

                if (cachedTranslationMessageIndex === -1) return message;

                return {
                    ...message,
                    translatedText: translatedMessages[cachedTranslationMessageIndex].translatedText,
                    translationShown: true,
                };
            });
        },
        updateMessageTranslationShownSucceeded: (
            state,
            action: PayloadAction<{ threadId: number; messageId: number; isTranslationShown: boolean }>,
        ) => {
            const { threadId, messageId, isTranslationShown } = action.payload;
            const index = findMessageIndex(state[threadId].entries, messageId);

            (state[threadId].entries[index] as TranslatableMessage).translationShown = isTranslationShown;
        },
    },
});

export const {
    fetchMessagesStarted,
    fetchMessagesSucceeded,
    fetchMessagesFailed,
    fetchPreviousMessagesStarted,
    fetchPreviousMessagesSucceeded,
    fetchPreviousMessagesFailed,
    sendMessageStarted,
    sendMessageSucceeded,
    sendMessageFailed,
    deleteMessageStarted,
    deleteMessageSucceeded,
    deleteMessageFailed,
    deleteMessageFromStore,
    deleteThreadMessages,
    messageReceived,
    updatePurchasableMessage,
    updateDeletedMessage,
    updateSeenMessages,
    restoreMessagesByThread,
    translateMessageStarted,
    translateMessageSucceeded,
    translateMessageFailed,
    setTranslatedMessagesInThread,
    updateMessageTranslationShownSucceeded,
} = messagesSlice.actions;
export default messagesSlice.reducer;
