import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SpendingMember } from 'domains/Member/Member.types';
import { MembersState } from './Members.types';

export const initialState: MembersState = {
    topPayingMembers: {
        performerId: undefined,
        status: 'initial',
        errorMessage: undefined,
        topPayingMembers: [],
    },
};

const membersSlice = createSlice({
    name: 'members',
    initialState,
    reducers: {
        fetchTopPayingMembersStarted: (state, action: PayloadAction<number>) => {
            state.topPayingMembers.status = 'pending';
            state.topPayingMembers.performerId = action.payload;
            state.topPayingMembers.errorMessage = undefined;
        },
        fetchTopPayingMembersSucceeded: (state, action: PayloadAction<SpendingMember[]>) => {
            state.topPayingMembers.status = 'resolved';
            state.topPayingMembers.topPayingMembers = action.payload;
        },
        fetchTopPayingMembersFailed: (state, action: PayloadAction<string>) => {
            state.topPayingMembers.status = 'rejected';
            state.topPayingMembers.errorMessage = action.payload;
        },
    },
});

export const { fetchTopPayingMembersStarted, fetchTopPayingMembersSucceeded, fetchTopPayingMembersFailed } =
    membersSlice.actions;
export default membersSlice.reducer;
